import { createGlobalState } from "@vueuse/core";
import { reactive, ref as refVue } from "vue";
import {
  collection,
  doc,
  onSnapshot,
  setDoc,
  getDocs,
  getDoc,
  updateDoc,
  query,
  where,
  orderBy,
  Unsubscribe,
} from "firebase/firestore";
import { db } from "@/services/firebase/Instance";
import _ from "lodash";
import { mappingTemplateId } from "~/utils/mappingTemplateId"

export default  createGlobalState(() => {

  return {
    _realtimeFetch,
    _realtimeFetchSortedWishes,
    _realtimeFetchSortedWishesById
  };

  function _realtimeFetch (eventId: string, callback: (event: Event) => void): Unsubscribe {   
    const q = query(
      collection(db,`events/${eventId}/wishes`)      
    );

    
    const unsubscribe = onSnapshot(q, (querySnapshot) => {     
      let wishes = []
      querySnapshot.forEach((doc) => { 
        const data = doc.data() 

        if(data?.templateId && (data?.status == 'approved' || data?.status == 'pending')) {

          if( !data?.version || data?.version !== 'v2' ) {
            wishes.push({ 
              ...doc.data(),
              id: doc.id, 
              templateId: mappingTemplateId(data?.templateId)
            })    
          }
        }

      }); 
      callback(wishes)
    });
    return unsubscribe
  }

  function _realtimeFetchSortedWishes(eventId: string, type:string, callback: (event: Event) => void) {
    const docRef = doc(db, `events/${eventId}/sorted/${type}`)

    //snapshot
    const unsubscribe = onSnapshot(docRef, (doc: any) => {
      const data = doc.data()
      callback(data)
    })
    return unsubscribe

    // const q = query(
    //   collection(db,`events/${eventId}/sorted`)      
    // );

    // const unsubscribe = onSnapshot(q, (querySnapshot) => {     
    //   let wishObj:any = {}
    //   querySnapshot.forEach((doc) => { 
    //     wishObj = doc.data() 
    //   }); 
    //   callback(wishObj)
    // });
    // return unsubscribe

  }

  function _realtimeFetchSortedWishesById(eventId: string, type:string, callback: (event: Event) => void) {
    const docRef = doc(db, `events/${eventId}/sorted/${type}`)

    //snapshot
    const unsubscribe = onSnapshot(docRef, (doc: any) => {
      const data = doc.data()
      callback(data)
    })
    return unsubscribe
  }
});
